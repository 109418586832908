import "core-js/stable";
import "regenerator-runtime/runtime";

const initEventTracking = () => {
    "use strict";

    var helpersModule = (function() {
            return {
                isGoogleAnalyticsAvailable: function() {
                    return (
                        typeof ga !== "undefined" || typeof gtag !== "undefined"
                    );
                },

                addClickEventListenerOnElements: function(elements, handler) {
                    for (var i = 0; i < elements.length; i++) {
                        elements[i].addEventListener("click", handler);
                    }
                },

                doesElementHaveTagAsParent: function(element, htmlTagName) {
                    if (element) {
                        if (element.tagName.toLowerCase() === htmlTagName) {
                            return true;
                        } else {
                            return doesElementHaveParentOfType(
                                element.parentElement,
                                htmlTagName
                            );
                        }
                    } else {
                        return false;
                    }
                },

                doesElementHaveParentWithClass: function(element, className) {
                    if (element) {
                        if (element.className.indexOf(className) > -1) {
                            return true;
                        } else {
                            return doesElementHaveParentWithClass(
                                element.parentElement,
                                className
                            );
                        }
                    } else {
                        return false;
                    }
                },

                // Recursively getting the parent Element which contains a href
                getAhrefParent: function getAhrefParent(element, counter) {
                    if (element && counter < 10) {
                        if (element.href) {
                            return element;
                        } else {
                            counter++;
                            return getAhrefParent(
                                element.parentElement,
                                counter
                            );
                        }
                    } else {
                        return false;
                    }
                },

                getParentElementWithTag: function(element, htmlTagName) {
                    if (element) {
                        if (element.tagName.toLowerCase() === htmlTagName) {
                            return element;
                        } else {
                            return getParentElementWithTag(
                                element.parentElement,
                                htmlTagName
                            );
                        }
                    } else {
                        return false;
                    }
                },

                getParentElementWithTagAndClass: function(
                    element,
                    htmlTagName,
                    className
                ) {
                    if (element) {
                        if (
                            element.tagName.toLowerCase() === htmlTagName &&
                            element.className.indexOf(className) > -1
                        ) {
                            return element;
                        } else {
                            return getParentElementWithTagAndClass(
                                element.parentElement,
                                htmlTagName,
                                className
                            );
                        }
                    } else {
                        return false;
                    }
                },

                isHrefValid: function(href) {
                    return typeof href === "string" && href.length > 0;
                },

                sendEvent: function sendEvent(category, action, label) {
                    category = category || "";
                    action = action || "";
                    label = label || "";

                    if (typeof gtag !== "undefined") {
                        gtag("event", action, {
                            event_category: category,
                            event_label: label
                        });
                    } else if (typeof ga !== "undefined") {
                        ga("send", "event", category, action, label);
                    }

                    if (window.uetq) {
                        window.uetq = window.uetq || [];
                        window.uetq.push({
                            ec: category,
                            ea: action,
                            el: label,
                            ev: 1
                        });
                    }
                },

                sendFbEvent: function(eventName, eventValue) {
                    if (typeof fbq !== "undefined") {
                        fbq("track", eventName, { content_name: eventValue });
                    }
                }
            };
        })(),
        eventTrackingModule = (function() {
            var whichSharePlatform = function(href) {
                    if (
                        href.indexOf("facebook") > -1 &&
                        href.indexOf("/sharer") > -1
                    ) {
                        return "Facebook";
                    }
                    if (
                        href.indexOf("twitter") > -1 &&
                        href.indexOf("/share?") > -1
                    ) {
                        return "Twitter";
                    }
                    if (
                        href.indexOf("plus.google") > -1 &&
                        href.indexOf("/share?") > -1
                    ) {
                        return "Google+";
                    }
                    if (
                        href.indexOf("pinterest") > -1 &&
                        href.indexOf("pin/create/bookmarklet") > -1
                    ) {
                        return "Pinterest";
                    }
                    if (
                        href.indexOf("linkedin") > -1 &&
                        href.indexOf("sharearticle") > -1
                    ) {
                        return "LinkedIn";
                    }
                    if (
                        href.indexOf("tumblr") > -1 &&
                        href.indexOf("share") > -1
                    ) {
                        return "Tumblr";
                    }
                    if (
                        href.indexOf("reddit") > -1 &&
                        href.indexOf("/submit?") > -1
                    ) {
                        return "Reddit";
                    }
                    return "";
                },
                isShareLink = function(href) {
                    return whichSharePlatform(href).length > 0;
                },
                isSocialLink = function(href) {
                    var socialNames = [
                        "twitter",
                        "facebook",
                        "linkedin",
                        "pinterest",
                        "tripadvisor",
                        "plus.google",
                        "instagram",
                        "tumblr",
                        "reddit",
                        "blogger.",
                        "yelp"
                    ];

                    for (var i = 0; i < socialNames.length; i++) {
                        if (href.toLowerCase().indexOf(socialNames[i]) > -1) {
                            return true;
                        }
                    }

                    return false;
                },
                notFromSameOrigin = function(href) {
                    var origin = window.location.origin;
                    if (origin) {
                        if (href.indexOf(origin) > -1) {
                            return false;
                        }
                        return true;
                    }
                    return true;
                },
                isPdf = function(href) {
                    if (href.indexOf(".pdf") > -1) {
                        return true;
                    }
                    return false;
                };

            return {
                email: function(href) {
                    if (href.indexOf("mailto:") > -1) {
                        var email = href.replace("mailto:", "");
                        helpersModule.sendEvent(
                            "Contact",
                            "Email Clicks",
                            email
                        );
                        helpersModule.sendFbEvent("Contact", "Email Clicks");
                    }
                },
                telephone: function(href) {
                    if (href.indexOf("tel:") > -1) {
                        var tel = href.replace("tel:", "");
                        helpersModule.sendEvent(
                            "Contact",
                            "Telephone Clicks",
                            tel
                        );
                        helpersModule.sendFbEvent(
                            "Contact",
                            "Telephone Clicks"
                        );
                    }
                },
                externalLink: function(href) {
                    if (
                        href.indexOf("http") > -1 &&
                        notFromSameOrigin(href) &&
                        !isSocialLink(href) &&
                        !isPdf(href)
                    ) {
                        helpersModule.sendEvent(
                            "External links",
                            "Clicks",
                            href
                        );
                    }
                },
                socialLink: function(href) {
                    if (
                        href.indexOf("http") > -1 &&
                        isSocialLink(href) &&
                        !isShareLink(href)
                    ) {
                        helpersModule.sendEvent("Social links", "Clicks", href);
                    }
                },
                socialShare: function(href) {
                    if (isShareLink(href)) {
                        if (typeof ga !== "undefined") {
                            ga(
                                "send",
                                "social",
                                whichSharePlatform(href),
                                "Share",
                                href
                            );
                        } else if (typeof gtag !== "undefined") {
                            gtag("event", "share", {
                                event_category: whichSharePlatform(href),
                                event_label: href
                            });
                        }
                    }
                },
                pdfLink: function(href) {
                    if (href.indexOf(".pdf") > -1) {
                        helpersModule.sendEvent(
                            "PDF downloads",
                            "Downloads",
                            href
                        );
                    }
                },
                trackClickOnElementWithId: function(
                    elementId,
                    idToTrack,
                    options
                ) {
                    if (elementId === idToTrack && options) {
                        helpersModule.sendEvent(
                            options.eventCategory,
                            options.eventAction,
                            options.eventLabel
                        );
                    }
                },
                trackClickOnElementWithClass: function(
                    elementClassName,
                    classNameToTrack,
                    options
                ) {
                    if (
                        elementClassName.indexOf(classNameToTrack) >= 0 &&
                        options
                    ) {
                        helpersModule.sendEvent(
                            options.eventCategory,
                            options.eventAction,
                            options.eventLabel
                        );
                    }
                },
                trackClickOnElementWithClassAndText: function trackClickOnElementWithClassAndText(
                    elementClassName,
                    classNameToTrack,
                    elementInnerText,
                    innerTextToTrack,
                    options
                ) {
                    if (
                        elementClassName.indexOf(classNameToTrack) >= 0 &&
                        innerTextToTrack.indexOf(innerTextToTrack) >= 0 &&
                        options
                    ) {
                        helpersModule.sendEvent(
                            options.eventCategory,
                            options.eventAction,
                            options.eventLabel
                        );
                    }
                },
                wrdevContactForm: function(formId) {
                    // The form Dictionary could be used when forms could be on more than one page.
                    // And the Url on which they are not is not reflecting their topic.
                    if (formId && formId.length > 0) {
                        var formDictionary = {
                                formId123: "Form Name"
                            },
                            formName = formDictionary[formId],
                            url = window.location.href.toLowerCase();

                        if (formName && formName.length > 0) {
                            helpersModule.sendEvent("Form", formName, url);
                            helpersModule.sendFbEvent(
                                "Lead",
                                formName.concat(" Submission")
                            );
                        } else {
                            helpersModule.sendEvent("Form", "Submitted", url);
                            helpersModule.sendFbEvent(
                                "Lead",
                                "Form Submission"
                            );
                        }
                    }
                }
            };
        })();

    if (helpersModule.isGoogleAnalyticsAvailable()) {
        document
            .querySelector("body")
            .addEventListener("click", function(event) {
                var hrefElement = helpersModule.getAhrefParent(event.target, 0),
                    href,
                    elementId = event.target.id,
                    elementClassName = event.target.className;

                if (
                    hrefElement &&
                    helpersModule.isHrefValid(hrefElement.href)
                ) {
                    href = hrefElement.href.toLowerCase();
                }

                if (href) {
                    eventTrackingModule.email(href);
                    eventTrackingModule.telephone(href);
                    eventTrackingModule.externalLink(href);
                    eventTrackingModule.socialLink(href);
                    eventTrackingModule.socialShare(href);
                    eventTrackingModule.pdfLink(href);
                }

                // Track Clicks on Accept cookie warning button
                if (elementClassName && elementClassName.length > 0) {
                    eventTrackingModule.trackClickOnElementWithClass(
                        elementClassName,
                        "cookie-warning-accept-button",
                        {
                            eventCategory: "Cookie Popup",
                            eventAction: "Button Click",
                            eventLabel: window.location.pathname.toLowerCase()
                        }
                    );
                }
            });

        // This event will be sent from wrdev-contact-form.js
        // When the form was submitted successfully.
        document.addEventListener(
            "FormSuccess",
            function(e) {
                if (e.detail.formId && e.detail.formId.length > 0) {
                    eventTrackingModule.wrdevContactForm(e.detail.formId);
                }
            },
            false
        );
    }
};

initEventTracking();
